import React, {Component} from "react";

export class Table extends Component {
    static displayName = Table.name;

    constructor(props) {
        super(props);
    }

    setActiveSegment = (segment) => {
        const updateState = this.props.updateState;
        updateState({
            selectedSegment: segment
        });
    };

    render() {
        return renderBody(this.props.getState, this.props.updateState, this.setActiveSegment)
    }
}

const renderBody = function (getState, updateState, setActiveSegment) {
    const state = getState();
    const market = state.market;
    const selectedSegment = state.selectedSegment;

    const sortBy = (func) => {
        const orig = [...state.segments];
        const sorted = orig.sort(func)
        updateState({segments: sorted})
    };

    return (
        <>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Segment Type</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.medianDom - b.medianDom)}>Median Dom</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.soldCount - b.soldCount)}>Sold Count</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.pendingCount - b.pendingCount)}>Pending Count</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.forSaleCount - b.forSaleCount)}>ForSale Count</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.medianSoldPrice - b.medianSoldPrice)}>Median Sold
                        Price
                    </th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.medianForSalePrice - b.medianForSalePrice)}>Median
                        ForSale Price
                    </th>
                    <th scope="col">Months of Supply</th>
                    <th scope="col">Absorption Rate (Per Month)</th>
                    <th scope="col">Pending For Sale Ratio</th>
                    <th scope="col">Sold % Change 15 Days</th>
                </tr>
                </thead>
                <tbody>
                {market.segments.map((segment) => {

                        let classStyle = "";

                        if (selectedSegment && selectedSegment.id === segment.id) {
                            classStyle = "table-warning";
                        }

                        return (
                            <tr key={segment.id} onClick={() => setActiveSegment(segment)} className={classStyle}>
                                <td>{segment.segmentType}</td>
                                <td>{segment.medianDom}</td>
                                <td>{segment.soldCount}</td>
                                <td>{segment.pendingCount}</td>
                                <td>{segment.forSaleCount}</td>
                                <td>{segment.medianSoldPrice}</td>
                                <td>{segment.medianForSalePrice}</td>
                                <td>{segment.marketInventoryMonths}</td>
                                <td>{segment.absorptionRate}</td>
                                <td>{segment.pendingForSaleRatio}</td>
                                <td>{segment.soldPercentChange}</td>
                            </tr>
                        );
                    }
                )}
                </tbody>
            </table>
        </>
    );
};