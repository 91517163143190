import {Status, Wrapper} from "@googlemaps/react-wrapper";
import React, {Component} from "react";
import {Marker} from "../../components/maps/Marker";
import {InfoWindow} from "../../components/maps/InfoWindow";
import {Map} from "../../components/maps/Map";
import {useLocation} from "react-router-dom";
import {Graphs} from "./Graphs";
import {Table} from "./Table";
import {DemandFilters} from "./DemandFilters";

class Demand extends Component {
    static displayName = Demand.name;

    constructor(props) {
        super(props);
        const searchParams = props.queryParameters;

        let dist = "10";
        if (searchParams.get('dist'))
            dist = searchParams.get('dist');

        let months = "12";
        if (searchParams.get('months'))
            months = searchParams.get('months');

        let coords = "32.772576,-97.015456"

        if (!this.props.getMethods().getState().authorizedGroups.texas)
            coords = "28.481059, -81.738981"
        
        if (searchParams.get('coords'))
            coords = searchParams.get('coords');

        this.state = {
            market: {},
            coords: coords,
            loading: true,
            dist: dist,
            months: months,
            subMarkets: [],
            selectedObject: {},
            activeMarker: {},
            showingInfoWindow: false,
            objectMarkers: [],
            selectedSegment: null,
            authorizedGroups: this.props.getMethods().getState().authorizedGroups
        };
    }

    getState = () => this.state;
    updateState = (object) => this.setState(object);
    getMethods = () => {
        return {
            setActiveMarker: this.setActiveMarker,
            removeActiveMarker: this.removeActiveMarker,
            addToMarkers: this.addToMarkers,
            populate: this.populate
        }
    }

    componentDidMount() {
        this.populate(this.getState, this.updateState);
    }

    setActiveMarker = (object) => {
        const marker = this.state.objectMarkers.find(a => a.object.id === object.id).marker;

        this.setState({
            selectedObject: object,
            activeMarker: marker,
            showingInfoWindow: true,
        });
    };

    removeActiveMarker = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                selectedObject: null,
                activeMarker: null,
                showingInfoWindow: false,
            });
        }
    };

    addToMarkers = (object, marker) => {
        if (!this.state.objectMarkers.some((a) => a.object.id === object.id)) {
            this.setState(prevState => ({
                objectMarkers: [...prevState.objectMarkers, {object: object, marker: marker}]
            }))
        }
    };

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (
            renderBody(this.getState, this.updateState, this.getMethods)
        );

        return <div>{contents}</div>;
    }

    async populate(getState, updateState) {
        const state = getState();

        const uri = 'demandlogic/calculate';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                latLon: state.coords,
                distance: state.dist,
                months: state.months,
                authorizedGroups: state.authorizedGroups
            })
        };

        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        updateState({
            market: data.market,
            subMarkets: data.subMarkets,
            loading: false,
            selectedSegment: data.market.landSegment
        });
    }
}

const renderBody = function (getState, updateState, getMethods) {

    const coords = getState().coords;
    const lat = Number(coords.split(",")[0].trim());
    const lng = Number(coords.split(",")[1].trim());

    const state = getState();

    const market = getState().market;
    const subMarkets = getState().subMarkets;

    const displayMarkers = function () {

        return subMarkets
            .map((subMarket) => {
                return (
                    <Marker
                        key={subMarket.id}
                        position={{
                            lat: subMarket.lat,
                            lng: subMarket.lon,
                        }}
                        icon={{
                            url: getIcon(subMarket.landSegment.hotnessCategory),
                        }}
                        object={subMarket}
                        getMethods={getMethods}
                    />
                );
            });
    };

    const getInfoWindowText = function (market) {

        const landSegment = market.landSegment;

        if (!landSegment) {
            return (
                <div>
                    No Listings Found <br/>
                </div>
            );
        }

        return (
            <div>
                SegmentType: {landSegment.segmentType} <br/>
                HotnessScore: {landSegment.hotnessScore} <br/>
                HotnessCategory: {landSegment.hotnessCategory} <br/>
                MedianDom: {landSegment.medianDom} <br/>
                MedianSoldPrice: {landSegment.medianSoldPrice} <br/>
                MedianForSalePrice: {landSegment.medianForSalePrice} <br/>
                SoldCount: {landSegment.soldCount} <br/>
                PendingCount: {landSegment.pendingCount} <br/>
                ForSaleCount: {landSegment.forSaleCount} <br/>
                Sold % Change: {landSegment.soldPercentChange} <br/>
                NewListings % Change: {landSegment.newListingsPercentChange} <br/>
                DomCount: {landSegment.domCount} <br/>
                ListingCount: {landSegment.listingCount} <br/>
                MaximumDom: {landSegment.maximumDom} <br/>
            </div>
        );
    };

    const getIcon = function (category) {

        if (category === 1) {
            return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/locations/purple.png";
        } else if (category === 2) {
            return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/locations/red.png";
        } else if (category === 3) {
            return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/locations/orange.png";
        } else if (category === 4) {
            return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/locations/yellow.png";
        } else {
            return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/locations/light-green.png";
        }
    };

    const render = (status) => {
        if (status === Status.FAILURE) return "Error";
        return "Loading...";
    };

    return (
        <div>
            <DemandFilters getState={getState} updateState={updateState} getMethods={getMethods}/>
            <Graphs getState={getState}/>
            <Table getState={getState} updateState={updateState}/>
            <Wrapper apiKey="AIzaSyCGGO3uwp6FRmVMdjcrXdTLVXyAOdn_v4U" render={render}>
                <Map
                    zoom={10}
                    center={{lat: lat, lng: lng}}
                    style={{width: "auto", height: "1000px"}}
                    onClick={getMethods().removeActiveMarker}
                >
                    {displayMarkers()}
                    <InfoWindow
                        visible={getState().showingInfoWindow}
                        marker={getState().activeMarker}
                    >
                        {getInfoWindowText(getState().selectedObject)}
                    </InfoWindow>
                </Map>
            </Wrapper>
        </div>
    );
};

function withQueryParameters(Component) {
    return function WrappedComponent(props) {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        return <Component {...props} queryParameters={queryParameters}/>;
    }
}

export default withQueryParameters(Demand);