import React, {Component, useEffect, useState} from 'react';
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import {Marker} from "../../components/maps/Marker";
import {Map} from "../../components/maps/Map";
import {useLocation} from "react-router-dom";
import {RealtorFilters} from "./RealtorFilters";
import Grid from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Unstable_Grid2";

class Realtors extends Component {
    static displayName = Realtors.name;

    constructor(props) {
        super(props);
        const searchParams = props.queryParameters;

        let dist = "75";
        if (searchParams.get('dist'))
            dist = searchParams.get('dist');

        let months = "12";
        if (searchParams.get('months'))
            months = searchParams.get('months');

        let coords = "32.772576,-97.015456"
        if (!this.props.getMethods().getState().authorizedGroups.texas)
            coords = "28.481059, -81.738981"
        if (searchParams.get('coords'))
            coords = searchParams.get('coords');

        let minAcres = "";
        if (searchParams.get('minAcres'))
            minAcres = searchParams.get('minAcres');

        let maxAcres = "";
        if (searchParams.get('maxAcres'))
            maxAcres = searchParams.get('maxAcres');

        let realtorType = "buyer";
        if (searchParams.get('realtorType'))
            realtorType = searchParams.get('realtorType');

        let propertyType = "vacantLand";
        if (searchParams.get('propertyType'))
            propertyType = searchParams.get('propertyType');

        this.state = {
            agents: [],
            coords: coords,
            loading: true,
            dist: dist,
            months: months,
            activeAgent: null,
            minAcres: minAcres,
            maxAcres: maxAcres,
            realtorType: realtorType,
            propertyType: propertyType,
            authorizedGroups: this.props.getMethods().getState().authorizedGroups
        };
    }

    getState = () => this.state;
    updateState = (object) => this.setState(object);
    getMethods = () => {
        return {
            addToMarkers: this.addToMarkers,
            setActiveAgent: this.setActiveAgent,
            populate: this.populate
        }
    }

    componentDidMount() {
        this.populate(this.getState, this.updateState);
    }

    setActiveAgent = (agent) => {
        this.setState({
            activeAgent: agent
        });
    };

    addToMarkers = (object, marker) => {
        if (!this.state.objectMarkers.some((a) => a.object.id === object.id)) {
            this.setState(prevState => ({
                objectMarkers: [...prevState.objectMarkers, {object: object, marker: marker}]
            }))
        }
    };

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (renderBody(this.getState, this.getMethods));

        return (
            <div>
                <RealtorFilters getState={this.getState} updateState={this.updateState} getMethods={this.getMethods}/>
                <Grid container spacing={1}>
                    <Grid xs>
                        <Item>
                            <h3 id="tabelLabel">Realtor Leaderboard</h3>
                        </Item>
                    </Grid>
                    <Grid xl={12}>
                        <Item>
                            {contents}
                        </Item>
                    </Grid>
                </Grid>
            </div>
        );
    }

    async populate(getState, updateState) {
        const state = getState();
        updateState({activeAgent: null})

        const uri = 'realtorslogic/calculate';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                latLon: state.coords,
                dist: state.dist,
                months: state.months,
                minAcres: state.minAcres,
                maxAcres: state.maxAcres,
                realtorType: state.realtorType,
                propertyType: state.propertyType,
                authorizedGroups: state.authorizedGroups
            })
        };

        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        updateState({agents: data, loading: false});
    }
}

const renderBody = function (getState, getMethods) {
    const urlTable = function () {
        if (getState().activeAgent) {
            const activeAgent = getState().activeAgent;

            return (
                <div>
                    <Wrapper apiKey="AIzaSyCGGO3uwp6FRmVMdjcrXdTLVXyAOdn_v4U" render={render}>
                        <Map
                            zoom={10}
                            center={{lat: activeAgent.centLat, lng: activeAgent.centLon}}
                            style={{width: "550px", height: "600px"}}
                        >
                            {displayMarkers(getState)}
                            {displayTarget(getState().coords)}
                        </Map>
                    </Wrapper>
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                        <tr key={"legend2"}>
                            <th>Closed Deal Urls for: {activeAgent.name}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activeAgent.deals.map(deal =>
                            <tr key={deal.number}>
                                <td><a href={deal.url} target="_blank" rel="noopener noreferrer">{deal.url}</a></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            );
        }
        return null;
    };

    const render = (status) => {
        if (status === Status.FAILURE) return "Error";
        return "Loading...";
    };

    const displayMarkers = function (getState) {

        const activeDeals = getState().activeAgent.deals;

        return activeDeals
            .map((deal) => {
                return (
                    <Marker
                        key={deal.number}
                        position={{
                            lat: deal.lat,
                            lng: deal.lon,
                        }}
                        getMethods={getMethods}
                        icon={{
                            url: "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/red.png",
                        }}
                    />
                );
            });
    };

    const displayTarget = function (coords) {
        const myArray = coords.split(",");
        const lat = Number(myArray[0]);
        const lon = Number(myArray[1]);

        return (
            <Marker
                key="Target"
                position={{
                    lat: lat,
                    lng: lon,
                }}
                icon={{
                    url: "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/purple.png",
                }}
            />
        );
    };


    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px"
            }}
        >
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                <tr key={"legend1"}>
                    <th>Name</th>
                    <th>Deal Count</th>
                    <th>Median Acres</th>
                    <th>Median Price</th>
                    <th>Agency Name</th>
                </tr>
                </thead>
                <tbody>
                {getState().agents.map(agent =>
                    <tr key={agent.Id} onClick={() => getMethods().setActiveAgent(agent)}>
                        <td>{agent.name}</td>
                        <td>{agent.transactionCount}</td>
                        <td>{agent.medianAcres}</td>
                        <td>{agent.medianPrice}</td>
                        <td>{agent.agencyName}</td>
                    </tr>
                )}
                </tbody>
            </table>
            {urlTable()}
        </div>
    );
};

function withQueryParameters(Component) {
    return function WrappedComponent(props) {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        return <Component {...props} queryParameters={queryParameters}/>;
    }
}

export default withQueryParameters(Realtors);

