import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import './custom.css';
import {Layout} from "./menu/Layout";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import Authorization from "./auth/Authorization";
import {Home} from "./pages/Home";
import Demand from "./pages/demand/Demand";
import Owners from "./pages/Owners";
import Realtors from "./pages/realtors/Realtors";
import Counties from "./pages/counties/Counties";
import NewConstruction from "./pages/newConstruction/NewConstruction";

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        
        this.state = {
            authorizedGroups : null
        }
    }

    getState = () => this.state;
    updateState = (object) => this.setState(object);
    getMethods = () => {
        return {
            getState: this.getState,
            updateState: this.updateState
        }
    }

    render() {
        return (
            <Layout>
                <AuthenticatedTemplate>
                    <Authorization getMethods={this.getMethods}>
                        <Routes>
                            <Route index={true} element={<Home getMethods={this.getMethods}/>}/>
                            <Route path={'/demand'} element={<Demand getMethods={this.getMethods}/>}/>
                            <Route path={'/realtors'} element={<Realtors getMethods={this.getMethods}/>}/>
                            <Route path={'/owners'} element={<Owners getMethods={this.getMethods}/>}/>
                            <Route path={'/counties'} element={<Counties getMethods={this.getMethods}/>}/>
                            <Route path={'/new-construction'} element={<NewConstruction getMethods={this.getMethods}/>}/>
                        </Routes>
                    </Authorization>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <p>You are not signed in! Please sign in.</p>
                </UnauthenticatedTemplate>
            </Layout>
        );
    }
}
