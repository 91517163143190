import React, {Component} from "react";
import {TextField, Button, InputLabel, Select, MenuItem, FormControl} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Item from '@mui/material/Unstable_Grid2';

export class NewConstructionFilters extends Component {
    static displayName = NewConstructionFilters.name;

    constructor(props) {
        super(props);

        this.state = {
            latLon: this.props.getState().coords.replace(/,/g, ', '),
            distance: this.props.getState().dist,
            years: this.props.getState().years,
            minAcres: this.props.getState().minAcres,
            maxAcres: this.props.getState().maxAcres
        };
    }

    onCalculate = async () => {

        if (this.state.latLon) {
            await this.props.updateState({
                coords: this.state.latLon.replace(/ /g, '')
            })
        }
        if (this.state.distance) {
            await this.props.updateState({
                dist: this.state.distance
            })
        }
        if (this.state.years) {
            await this.props.updateState({
                years: this.state.years
            })
        }
        if (this.state.minAcres) {
            await this.props.updateState({
                minAcres: this.state.minAcres
            })
        }
        if (this.state.maxAcres) {
            await this.props.updateState({
                maxAcres: this.state.maxAcres
            })
        }
        await this.props.updateState({
            loading: true
        })

        this.props.getMethods().populate(this.props.getState, this.props.updateState);
    }

    render() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid xs={2.25}>
                        <Item>
                            <TextField fullWidth id="outlined-basic" label="Latitude, Longitude" variant="outlined"
                                       value={this.state.latLon}
                                       onChange={(e) => this.setState({latLon: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField id="outlined-basic" label="Distance (miles)" variant="outlined"
                                       value={this.state.distance}
                                       onChange={(e) => this.setState({distance: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField id="outlined-basic" label="Years Built" variant="outlined"
                                       value={this.state.years}
                                       onChange={(e) => this.setState({years: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField id="outlined-basic" label="Min Acres" variant="outlined"
                                       value={this.state.minAcres}
                                       onChange={(e) => this.setState({minAcres: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField id="outlined-basic" label="Max Acres" variant="outlined"
                                       value={this.state.maxAcres}
                                       onChange={(e) => this.setState({maxAcres: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs="auto">
                        <Item style={{paddingTop: 15}}>
                            <Button variant="contained"
                                    onClick={() => this.onCalculate()}>
                                Search
                            </Button>
                        </Item>
                    </Grid>
                </Grid>
            </div>
        );
    }
}