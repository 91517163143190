import React, {Component} from "react";
import {TextField, Button, InputLabel, Select, MenuItem, FormControl} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Item from '@mui/material/Unstable_Grid2';

export class RealtorFilters extends Component {
    static displayName = RealtorFilters.name;

    constructor(props) {
        super(props);

        this.state = {
            latLon: this.props.getState().coords.replace(/,/g, ', '),
            distance: this.props.getState().dist,
            months: this.props.getState().months,
            minAcres: this.props.getState().minAcres,
            maxAcres: this.props.getState().maxAcres,
            realtorType: this.props.getState().realtorType,
            propertyType: this.props.getState().propertyType
        };
    }

    onCalculate = async () => {

        if (this.state.latLon) {
            await this.props.updateState({
                coords: this.state.latLon.replace(/ /g, '')
            })
        }
        if (this.state.distance) {
            await this.props.updateState({
                dist: this.state.distance
            })
        }
        if (this.state.months) {
            await this.props.updateState({
                months: this.state.months
            })
        }
        if (this.state.minAcres) {
            await this.props.updateState({
                minAcres: this.state.minAcres
            })
        }
        if (this.state.maxAcres) {
            await this.props.updateState({
                maxAcres: this.state.maxAcres
            })
        }
        if (this.state.realtorType) {
            await this.props.updateState({
                realtorType: this.state.realtorType
            })
        }
        if (this.state.propertyType) {
            await this.props.updateState({
                propertyType: this.state.propertyType
            })
        }

        await this.props.updateState({
            loading: true
        })

        this.props.getMethods().populate(this.props.getState, this.props.updateState);
    }

    render() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid xs={2.25}>
                        <Item>
                            <TextField fullWidth id="outlined-basic" label="Latitude, Longitude" variant="outlined"
                                       value={this.state.latLon}
                                       onChange={(e) => this.setState({latLon: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField id="outlined-basic" label="Distance (miles)" variant="outlined"
                                       value={this.state.distance}
                                       onChange={(e) => this.setState({distance: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField id="outlined-basic" label="Months" variant="outlined"
                                       value={this.state.months}
                                       onChange={(e) => this.setState({months: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField id="outlined-basic" label="Min Acres" variant="outlined"
                                       value={this.state.minAcres}
                                       onChange={(e) => this.setState({minAcres: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField id="outlined-basic" label="Max Acres" variant="outlined"
                                       value={this.state.maxAcres}
                                       onChange={(e) => this.setState({maxAcres: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs={2}>
                        <Item>
                            <FormControl fullWidth>
                                <InputLabel id="RealtorType-simple-select-label">Realtor Type</InputLabel>
                                <Select
                                    labelId="RealtorType-simple-select-label"
                                    id="RealtorType-simple-select"
                                    value={this.state.realtorType}
                                    label="RealtorType"
                                    onChange={(e) => this.setState({realtorType: e.target.value})}
                                >
                                    <MenuItem value={"buyer"}>Buyer</MenuItem>
                                    <MenuItem value={"seller"}>Seller</MenuItem>
                                    <MenuItem value={"buyerAndSeller"}>Buyer and Seller</MenuItem>
                                </Select>
                            </FormControl>
                        </Item>
                    </Grid>
                    <Grid xs={2.25}>
                        <Item>
                            <FormControl fullWidth>
                                <InputLabel id="PropertyType-simple-select-label">Property Type</InputLabel>
                                <Select
                                    labelId="PropertyType-simple-select-label"
                                    id="PropertyType-simple-select"
                                    value={this.state.propertyType}
                                    label="PropertyType"
                                    onChange={(e) => this.setState({propertyType: e.target.value})}
                                >
                                    <MenuItem value={"vacantLand"}>Vacant Land</MenuItem>
                                    <MenuItem value={"singleFamilyResidential"}>Single Family Residential</MenuItem>
                                    <MenuItem value={"mobile"}>Mobile Home</MenuItem>
                                </Select>
                            </FormControl>
                        </Item>
                    </Grid>
                    <Grid xs="auto">
                        <Item style={{paddingTop: 15}}>
                            <Button variant="contained"
                                    onClick={() => this.onCalculate()}>
                                Search
                            </Button>
                        </Item>
                    </Grid>
                </Grid>
            </div>
        );
    }
}