import {Component} from "react";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";

export class Graphs extends Component {
    static displayName = Graphs.name;

    constructor(props) {
        super(props);
    }

    render() {
        return renderBody(this.props.getState)
    }
}

const renderBody = function (getState) {

    const data = getState().selectedSegment.trendPointsByTimespan;

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px"
                }}
            >
                <LineChart
                    width={650}
                    height={600}
                    data={data}
                    margin={{
                        top: 20,
                        right: 5,
                        bottom: 20,
                        left: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="medianPrice" name="Median Sold Price" stroke="#8884d8"/>
                    <Line type="monotone" dataKey="medianPriceChange" name="Change in Median Sold Price"
                          stroke="#82ca9d"/>
                </LineChart>
                <LineChart
                    width={650}
                    height={600}
                    data={data}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="medianPricePerAcre" name="Median Price Per Acre" stroke="#8884d8"/>
                    <Line type="monotone" dataKey="medianPricePerAcreChange" name="Change in Price Per Acre"
                          stroke="#82ca9d"/>
                </LineChart>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px"
                }}
            >
                <LineChart
                    width={650}
                    height={600}
                    data={data}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="soldCount" name="Sales Velocity" stroke="#8884d8"/>
                    <Line type="monotone" dataKey="soldCountChange" name="Change in Sales Velocity" stroke="#82ca9d"/>
                </LineChart>
                <LineChart
                    width={650}
                    height={600}
                    data={data}
                    margin={{
                        top: 20,
                        right: 5,
                        bottom: 20,
                        left: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="medianDom" name="Median Dom"
                          stroke="#8884d8"/>
                    <Line type="monotone" dataKey="medianDomChange" name="Change in Median Dom"
                          stroke="#82ca9d"/>
                </LineChart>
            </div>
        </>
    );
};