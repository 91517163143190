import React, {Component} from "react";
import {useLocation} from "react-router-dom";
import {RealtorFilters} from "../realtors/RealtorFilters";
import Grid from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Unstable_Grid2";
import {NewConstructionFilters} from "./NewConstructionFilters";

class NewConstruction extends Component {
    static displayName = NewConstruction.name;

    constructor(props) {
        super(props);
        const searchParams = props.queryParameters;

        let dist = "75";
        if (searchParams.get('dist'))
            dist = searchParams.get('dist');

        let years = "1";
        if (searchParams.get('years'))
            years = searchParams.get('years');

        let coords = "32.772576,-97.015456"
        if (searchParams.get('coords'))
            coords = searchParams.get('coords');

        let minAcres = "1";
        if (searchParams.get('minAcres'))
            minAcres = searchParams.get('minAcres');

        let maxAcres = "";
        if (searchParams.get('maxAcres'))
            maxAcres = searchParams.get('maxAcres');

        this.state = {
            coords: coords,
            loading: true,
            dist: dist,
            years: years,
            minAcres: minAcres,
            maxAcres: maxAcres,
            builders: []
        };
    }

    componentDidMount() {
        this.populate(this.getState, this.updateState);
    }

    getState = () => this.state;
    updateState = (object) => this.setState(object);
    getMethods = () => {
        return {
            populate: this.populate
        }
    }

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (renderBody(this.getState, this.updateState));

        return (
            <div>
                <NewConstructionFilters getState={this.getState} updateState={this.updateState}
                                        getMethods={this.getMethods}/>
                <Grid container spacing={1}>
                    <Grid xs>
                        <Item>
                            <h3 id="tabelLabel">New Construction</h3>
                        </Item>
                    </Grid>
                    <Grid xl={12}>
                        <Item>
                            {contents}
                        </Item>
                    </Grid>
                </Grid>
            </div>
        );
    }

    async populate(getState, updateState) {
        const uri = 'newconstructionlogic/calculate';
        const state = getState();

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                latLon: state.coords,
                dist: state.dist,
                years: state.years,
                minAcres: state.minAcres,
                maxAcres: state.maxAcres
            })
        };

        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        updateState({builders: data.builders, loading: false});
    }
}

const renderBody = function (getState, updateState) {

    const state = getState();

    const sortBy = (func) => {
        const orig = [...state.counties];
        const sorted = orig.sort(func)
        updateState({counties: sorted})
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px"
            }}
        >
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                <tr key={"legend1"}>
                    <th scope="col">Realtor Name</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.medianPrice - b.medianPrice)}>Median Price</th>
                    <th scope="col">Number of Deals</th>
                </tr>
                </thead>
                <tbody>
                {state.builders.map((builder) => {
                        return (
                            <tr key={builder.id}>
                                <td>{builder.agentName}</td>
                                <td>{builder.medianPrice}</td>
                                <td>{builder.numberOfDeals}</td>
                            </tr>
                        );
                    }
                )}
                </tbody>
            </table>
        </div>
    );
};

function withQueryParameters(Component) {
    return function WrappedComponent(props) {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        return <Component {...props} queryParameters={queryParameters}/>;
    }
}

export default withQueryParameters(NewConstruction);