import React, {Component} from "react";
import {TextField, Button} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Item from '@mui/material/Unstable_Grid2';

export class DemandFilters extends Component {
    static displayName = DemandFilters.name;

    constructor(props) {
        super(props);

        this.state = {
            latLon: this.props.getState().coords.replace(/,/g, ', '),
            distance: this.props.getState().dist,
            months: this.props.getState().months
        };
    }

    onCalculate = async () => {

        if (this.state.latLon) {
            await this.props.updateState({
                coords: this.state.latLon.replace(/ /g, '')
            })
        }
        if (this.state.distance) {
            await this.props.updateState({
                dist: this.state.distance
            })
        }
        if (this.state.months) {
            await this.props.updateState({
                months: this.state.months
            })
        }

        await this.props.updateState({
            loading: true
        })

        this.props.getMethods().populate(this.props.getState, this.props.updateState);
    }

    render() {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid xs={3}>
                        <Item>
                            <TextField fullWidth id="outlined-basic" label="Latitude, Longitude" variant="outlined"
                                       value={this.state.latLon}
                                       onChange={(e) => this.setState({latLon: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField id="outlined-basic" label="Distance (miles)" variant="outlined"
                                       value={this.state.distance}
                                       onChange={(e) => this.setState({distance: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField id="outlined-basic" label="Months" variant="outlined"
                                       value={this.state.months}
                                       onChange={(e) => this.setState({months: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item style={{paddingTop: 15}}>
                            <Button variant="contained"
                                    onClick={() => this.onCalculate()}>
                                Calculate
                            </Button>
                        </Item>
                    </Grid>
                    <Grid xs={4}>
                        <Item>
                        </Item>
                    </Grid>
                </Grid>
            </div>
        );
    }
}