import React, {Component} from "react";
import {useLocation} from "react-router-dom";

class Counties extends Component {
    static displayName = Counties.name;

    constructor(props) {
        super(props);
        const searchParams = props.queryParameters;

        this.state = {
            loading: true,
            counties: []
        };
    }

    componentDidMount() {
        this.populateCounties();
    }

    getState = () => this.state;
    updateState = (object) => this.setState(object);

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (renderBody(this.getState, this.updateState));

        return (
            <div>
                <h1 id="tabelLabel">County Demand</h1>
                <p>
                    Important statistics by County.
                </p>
                <br/>
                {contents}
            </div>
        );
    }

    async populateCounties() {
        const uri = 'countieslogic/list';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({})
        };

        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        this.setState({counties: data.counties, loading: false});
    }
}

const renderBody = function (getState, updateState) {

    const state = getState();

    const sortBy = (func) => {
        const orig = [...state.counties];
        const sorted = orig.sort(func)
        updateState({counties: sorted})
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px"
            }}
        >
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                <tr key={"legend1"}>
                    <th scope="col">County Name</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.medianDom - b.medianDom)}>Median Dom</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.soldCount - b.soldCount)}>Sold Count</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.pendingCount - b.pendingCount)}>Pending Count</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.forSaleCount - b.forSaleCount)}>ForSale Count</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.medianSoldPrice - b.medianSoldPrice)}>Median Sold
                        Price
                    </th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.medianForSalePrice - b.medianForSalePrice)}>Median
                        ForSale Price
                    </th>
                    <th scope="col">Months of Supply</th>
                    <th scope="col">Absorption Rate (Per Month)</th>
                    <th scope="col">Pending For Sale Ratio</th>
                    <th scope="col">Sold % Change 15 Days</th>
                </tr>
                </thead>
                <tbody>
                {state.counties.map((county) => {
                        const segment = county.market.landSegment
                        return (
                            <tr key={segment.id}>
                                <td>{county.name}</td>
                                <td>{segment.medianDom}</td>
                                <td>{segment.soldCount}</td>
                                <td>{segment.pendingCount}</td>
                                <td>{segment.forSaleCount}</td>
                                <td>{segment.medianSoldPrice}</td>
                                <td>{segment.medianForSalePrice}</td>
                                <td>{segment.marketInventoryMonths}</td>
                                <td>{segment.absorptionRate}</td>
                                <td>{segment.pendingForSaleRatio}</td>
                                <td>{segment.soldPercentChange}</td>
                            </tr>
                        );
                    }
                )}
                </tbody>
            </table>
        </div>
    );
};

function withQueryParameters(Component) {
    return function WrappedComponent(props) {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        return <Component {...props} queryParameters={queryParameters}/>;
    }
}

export default withQueryParameters(Counties);