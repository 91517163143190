import {useEffect, useState} from "react";

export const Marker = (options) => {
    const [marker, setMarker] = useState();


    useEffect
    (() => {
        if (!marker) {
            const newMarker = new window.google.maps.Marker();
            setMarker(newMarker);
            if (options.object)
                options.getMethods().addToMarkers(options.object, newMarker);
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
            if (!window.google.maps.event.hasListeners(marker, "click")) {
                marker.addListener("click", function () {
                    options.getMethods().setActiveMarker(options.object);
                });
            }
            /*
            if (!window.google.maps.event.hasListeners(marker, "rightclick")) {
                marker.addListener("rightclick", function () {
                    getMethods().removeLandFromSelected(options.land);
                });
            }
            if (!window.google.maps.event.hasListeners(marker, "dblclick")) {
                marker.addListener("dblclick", function () {
                    getMethods().addLandToSelected(options.land);
                });
            }
             */
        }
    }, [marker, options]);

    return null;
};